<template>
  <b-card no-body>
    <b-card-body>
      <b-card-title>Permissions</b-card-title>
      <b-card-sub-title>Permission according to roles</b-card-sub-title>
    </b-card-body>
    <b-table responsive :items="permissionsData" class="mb-0">
      <template #cell(module)="data">
        {{ title(data.value) }}
      </template>
      <template #cell()="data">
        <b-form-checkbox disabled :checked="data.value" />
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox } from 'bootstrap-vue';
import { barePermissionsData, initialAbility, permissionsData } from '@/libs/acl/config';
import { title } from '@core/utils/filter';

export default {
  components: {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const applyAbility = (permissions, abilities) => {
      let modules = { ...permissions };

      for (let ability of abilities) {
        if (ability.subject === 'system') continue;
        if (!modules[ability.subject]) modules[ability.subject] = { read: false, write: false, create: false, delete: false };
        modules[ability.subject][ability.action] = true;
      }

      return Object.keys(modules).map((moduleName) => {
        return { module: moduleName, ...modules[moduleName] };
      });
    };

    let abilities = [...initialAbility];

    if (props.userData.ability) {
      abilities.push(...props.userData.ability);
    }

    let pmd = applyAbility({}, abilities);

    return {
      permissionsData: pmd,
      title,
    };
  },
};
</script>

<style></style>
